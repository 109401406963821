var idioma = $('html').attr('lang');
$('.houdiniForm').each(function() {//per cada formulari
  'use strict';
  //validacio formulari
  var form = $(this);
  form.validate({
    errorPlacement: function(error, element) {
      if (element.attr('type') === 'checkbox' || element.attr('type') === 'radio') {
        if (element.closest('div').find('input').length > 1) {
          error.insertAfter(element.closest('div').find('p.type-label'));
        } else {
          error.insertAfter(element.parent('label'));
        }
      } else {
        error.insertAfter(element);
      }
    }
  });
  
  if ($(this).find('.form-steps').length > 0) {
    $(this).find('.form-steps').steps({
      headerTag: "h3",
      bodyTag: "section",
      transitionEffect: "fade",
      transitionEffectSpeed: 200,
      cssClass: "wizard",
      autoFocus: true,
      enableAllSteps: false,
      enablePagination: true,
      enableFinishButton: false,
      onStepChanging: function (event, currentIndex, newIndex)
      {
          if (newIndex < currentIndex) {
              $(this).find('div.actions').show();
              return true;
          }
          form.validate().settings.ignore = ":disabled,:hidden";
          var formValid = form.valid();
          if (!formValid) {
              return false;
          }
          if (newIndex + 1 == $(this).find('section').length) {
              $(this).find('div.actions').hide();
          } else {
              $(this).find('div.actions').show();
          }
          return formValid;
      },
      onFinishing: function (event, currentIndex)
      {
          form.validate().settings.ignore = ":disabled";
          return form.valid();
      },
      labels: {
          cancel: $.houdiniforms.messages.steps_cancel,
          current: $.houdiniforms.messages.steps_current,
          pagination: $.houdiniforms.messages.steps_pagination,
          finish: $.houdiniforms.messages.steps_finish,
          next: $.houdiniforms.messages.steps_next,
          previous: $.houdiniforms.messages.steps_previous,
          loading: $.houdiniforms.messages.steps_loading,
      }
    });
  }  
  
  var others = $(this).find('option[value="others_field"]');
  others.each(function(){
    $(this).closest('div').find('input[type="text"]').hide();
    $(this).closest('div').find('select').on('change', function(){
      if ($(this).closest('div').find('select').val() == 'others_field') {
        $(this).closest('div').find('input[type="text"]').show();
      } else {
        $(this).closest('div').find('input[type="text"]').hide().val('');
      }
    });
  });

  var othersCheck = $(this).find('input[data-type="others_field"]');
  othersCheck.each(function(){
    $(this).closest('div').find('input[type="text"]').hide();
     $(this).closest('div').find('input').on('change', function(){
      if ($(this).closest('div').find('input[data-type="others_field"]').is(":checked")){
        $(this).closest('div').find('input[type="text"]').show();
        $(this).closest('div').find('input[type="text"]').focus();
      }else{
         $(this).closest('div').find('input[type="text"]').hide();
      }
    });
  });
});

//datapicker

$('.houdiniForm .hasDatepicker').fdatepicker({
  language: idioma,
  format: 'dd/mm/yyyy',
  startDate: '01/01/1900',
  leftArrow:'<<',
  rightArrow:'>>'
});

//chosen
$('.houdiniForm .chzn-select').select2();
$('.multiple-select-help').hide(); //amaga misatge d'ajuda dels selects multiples
$('.chzn-select').select2();

$.fn.raty.defaults.path = '/media/img';
$.fn.raty.defaults.cancel = false;

//valoracio
$('.houdiniForm .scoreField').each(function() {
  'use strict';
  var field = $(this);
  var id = '#' + field.attr('id');
  var score = $('<div></div>');
  score.raty({
    half: true,
    hints: ['', '', '', '', ''],
    score: function() {
      return field.val();
    },
    targetScore: id
  });
  field.hide();
  field.after(score);
});

//select de dnis
$('.houdiniForm .dninifnie').each(function() {
  'use strict';
  $(this).find('select').on('change', function() {
    var value = $(this).val();
    var input = $(this).closest('div.row').find('input');

    if (value === 'DNI') {
      input.rules('add', {
        dni: true,
        cifES: false,
        nieES: false
      });
    } else if (value === 'CIF') {
      input.rules('add', {
        dni: false,
        cifES: true,
        nieES: false
      });
    } else if (value === 'NIE') {
      input.rules('add', {
        dni: false,
        cifES: false,
        nieES: true
      });
    }
  });
});


